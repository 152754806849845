'use strict';

var processInclude = require('base/util');

function pdSlider() {

    $('.experience-component .carousel').each(function() {
        $(this).find('.pd-slider').on('init', function(event, slick) {
            $(this).find('.product .tile-tag').matchHeight();
            $(this).find('.product .tile-body').matchHeight();
            $(this).find('.product .tile-buttons').matchHeight();
            $(this).find('.product').matchHeight();
        });
    });

    if ($('.pd-slider.slick-initialized').length) {
        $('.pd-slider').slick('unslick');
    }

    // Detect if page in in the editor
    if (window.location !== window.parent.location) {
        $(".experience-page").addClass('in-editor');

        $('.experience-component .carousel').each(function() {
            if(!$(this).hasClass('edit-mode')){
                $(this).find('.pd-slider').slick();
            }
        });
    } else {
        $('.experience-component .carousel').each(function() {
            $(this).find('.pd-slider').slick();
        });
    }
}

function getCustomColors () {
    let customBtn = document.querySelectorAll('.btn-custom');
    if (customBtn.length) {
        for (let b = 0; b < customBtn.length; b++) {
            customBtn[b].style.setProperty('--bgColor', customBtn[b].dataset.bgcolor);
            customBtn[b].style.setProperty('--textColor', customBtn[b].dataset.textcolor);
        }
    }
}

$(document).ready(function () {
    // processInclude(require('pagedesigner/experience/imageAndText'));
    // processInclude(require('pagedesigner/experience/featureFocus'));
    // processInclude(require('pagedesigner/experience/videoTile'));
    processInclude(require('pagedesigner/experience/spacer'));
    // Include Instagram client JS
    processInclude(require('core/components/instagram'));

    $('.pd-text-slider').slick({
        dots: true,
        arrows: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1
    });

    // Detect if page in in the editor
    if (window.location !== window.parent.location) {
        $('.experience-page').addClass('in-editor');

        $('.experience-component .carousel').each(function (index) {
            if (!$(this).hasClass('edit-mode')) {
                $(this).find('.pd-slider').slick();
            }
        });
    } else {
        $('.pd-slider').slick();
    }

    pdSlider();
    getCustomColors();

    

    $(document).on('click','.isSamePageGrid a',function() {
        $('html, body').animate({
            scrollTop: $(".container.search-results").offset().top - 150
        }, 400);
        return false;
    });

    $(document).on('click','.isNavProductGrid a',function() {
        localStorage.setItem("isNavProductGrid", 1);
    });

});

$(window).smartresize(function() {
    pdSlider();
});

